/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html, body { height: 100%; }
body { margin: 0; font-family: 'Times New Roman', Times, sans-serif; }



  .formulario-autenticacion {
	min-width: 151px;
	max-width: 99%;
	width: 100%;

  }

  .autenticacion {
	width: 99%;
  }
  .login-form {
	margin: 10px;
	width: 40%;
z-index: auto;

  }
  .claveExpasonra {
	width: 92%;
  }

.title_center {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
.chart_box {
  width: 900px;
  margin: 0 auto;
  align-items: center;
  display: inline-block;
}

table {
  width: 100%;
}

.menu-espasivo {
	flex: 1 1 auto;
  object-position: 0%;
  }

  .rowFairvoting {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
  opacity: unset;
  }
  .blockFaivoting {
	width: 101px;
  opacity: unset;
  }

